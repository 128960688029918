import { Component, Input } from '@angular/core';

import { ActionMessage } from '@shared/index';


@Component({
  selector: 'app-action-messages',
  templateUrl: './action-messages.component.html'
})
export class ActionMessagesComponent {

  @Input() messages: ActionMessage[];

}

