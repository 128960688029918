import { Task } from "@dashboard/shared";

/**
 * UserTask models.
 */
export class UserTask {
  Id?: Number;
  UserId?: number;
  TaskId?: number;
  task?: Task;
}
