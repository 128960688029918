import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { pluck, distinctUntilChanged } from 'rxjs/operators';

import { State } from '@shared/index';


const state: State = {
  employees: undefined,
  clients: undefined,
  reports: undefined,
  settings: undefined,
  timelogs: undefined,
  permissions: undefined,
  tasks: undefined,
  vacations: undefined
};

@Injectable({ providedIn: 'root' })
export class StoreService {

  private subject = new BehaviorSubject<State>(state);
  private store = this.subject.asObservable().pipe(distinctUntilChanged());

  get value() {
    return this.subject.value;
  }

  select<T>(name: string): Observable<T> {
    return this.store.pipe(pluck(name));
  }

  set(name: string, state: any) {
    this.subject.next({ ...this.value, [name]: state });
  }

}
