// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  version: '2.0.0',
  firebase: {
    apiKey: "AIzaSyDf4GRMz7AN5hnJvvrzXzQ1MaI-UK5Pnws",
    authDomain: "bclok-pushnotifications.firebaseapp.com",
    projectId: "bclok-pushnotifications",
    storageBucket: "bclok-pushnotifications.appspot.com",
    messagingSenderId: "145659493208",
    appId: "1:145659493208:web:189f1617c0fbf3cebfc32d"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
