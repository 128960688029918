import { Injectable } from '@angular/core';
import { Router, CanLoad, Route, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '@core/index';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanLoad, CanActivate {

  constructor(private router: Router,
              private authService: AuthService) { }


  /**
   * Loads a specific route module if currentUser is loggedIn. If not, redirect back to Login Page
   *
   * @param route
   * @param state
   */
  canLoad(route: Route) {
    if (this.authService.user) return true;

    this.router.navigate(['/login']);
    return false;
  }

  /**
   * Loads a specific route module if currentUser is not loggedIn. If not, redirect to Dashboard Page
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.user) return true;

    this.router.navigate(['/dashboard']);
    return false;
  }
}
