import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';

import { Theme } from '@shared/index';


/**
 * Service for managing users.
 */
@Injectable({ providedIn: 'root' })
export class ThemeService {

  private themingUrl = `${this.serviceUrl}/api/theme/company`;
	private httpOptions = {
						    headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })};

  themeWrapper = document.querySelector('body');

  constructor(@Inject('SERVICE_URL') private serviceUrl: string,
              private http: HttpClient,
              private helper: JwtHelperService) { }


  /**
   * Get Company Id
   */
  get companyId() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const data = this.helper.decodeToken(user.token);
    return data.IdCompany;
  }

  public get getTheme() {
    return JSON.parse(localStorage.getItem('theme'));
  }

  fetchTheme(): Observable<any> {
    const companyId = this.companyId;
    localStorage.setItem('companyId', companyId);
    
    return this.http
      .get(`${this.themingUrl}/${companyId}`)
      .pipe(tap(data => {
        if (data) {
          localStorage.setItem('theme', JSON.stringify(data));
          this.setTheme(data);
        }else{
          if(data == null){
            console.log('no theme found');
            // this.defaultTheme(this.companyId).subscribe(data => {
            //   if(data){
            //     localStorage.setItem('theme', JSON.stringify(data));
            //     this.setTheme(data);
            //   }
            // });
          }
        }

      }));
  }

  defaultTheme(companyId: number): Observable<any>{
    return this.http.get(this.serviceUrl +'/api/theme/defualtTheme/'+companyId);
  }

	saveTheme(data: any): Observable<any> {
		return this.http.post<Theme>(this.serviceUrl + '/api/theme/save', data);
	}


  submitCompanyLogos(data: any){
    return this.http.post<any>(this.serviceUrl + "/api/theme/submitCompanyLogos", data);
  }
  postTemplate(data: any): Observable<any>{
    return this.http.post<Theme>(this.serviceUrl + '/api/theme/postTemplate', data);
  }

  getThemes(): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/theme/all');
  }

  getTemplates(): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/theme/getTemplates');
  }

  setTheme(palette: Theme) {
    // Background
    this.themeWrapper.style.setProperty('--background-primary', palette.backgroundPrimary);
    this.themeWrapper.style.setProperty('--background-secondary', palette.backgroundSecondary);
    this.themeWrapper.style.setProperty('--background-default', palette.backgroundDefault);
    this.themeWrapper.style.setProperty('--background-tertiary', palette.backgroundTertiary);
    this.themeWrapper.style.setProperty('--background-quaternary', palette.backgroundQuaternary);

    // Fonts
    this.themeWrapper.style.setProperty('--font-primary', palette.fontPrimary);
    this.themeWrapper.style.setProperty('--font-secondary', palette.fontSecondary);
    this.themeWrapper.style.setProperty('--font-default', palette.fontDefault);
    this.themeWrapper.style.setProperty('--font-tertiary', palette.fontTertiary);

    // Buttons bg
    this.themeWrapper.style.setProperty('--button-primary', palette.buttonPrimary);
    this.themeWrapper.style.setProperty('--button-secondary', palette.buttonSecondary);
    this.themeWrapper.style.setProperty('--button-default', palette.buttonDefault);

    // Buttons font
    this.themeWrapper.style.setProperty('--button-primary-font', palette.buttonPrimaryFont);
    this.themeWrapper.style.setProperty('--button-secondary-font', palette.buttonSecondaryFont);


    // Input states bg
    this.themeWrapper.style.setProperty('--normal-state', palette.normalStateBackground);
    this.themeWrapper.style.setProperty('--saved-state', palette.savedStateBackground);
    this.themeWrapper.style.setProperty('--submit-state', palette.submitStateBackground);

    // Table bg
    this.themeWrapper.style.setProperty('--table-primary', palette.tablePrimary);
    this.themeWrapper.style.setProperty('--table-secondary', palette.tableSecondary);
    this.themeWrapper.style.setProperty('--table-default', palette.tableDefault);
    this.themeWrapper.style.setProperty('--table-tertiary', palette.tableTertiary);
    this.themeWrapper.style.setProperty('--table-quaternary', palette.tableQuaternary);

    // Badge bg
    this.themeWrapper.style.setProperty('--badge-primary', palette.badgePrimary);

  }


  toggleContractors(isContractors: any) {
    return this.http.get(this.serviceUrl + '/api/theme/toggleContractors?isContractors=' + isContractors + '&companyId=' + this.companyId);
  }

}
