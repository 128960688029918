import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@core/auth/auth.guard';
import { CustomPreload } from '@core/index';
import { StripeGuard } from './shared/guards/stripe.guard';


const routes: Routes = [
  { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), canActivate: [AuthGuard],  data: { preload: true } },
  { path: 'customer', loadChildren: () => import('./installation/installation.module').then(m => m.InstallationModule),canLoad: [], data: { preload: true}},
  { path: 'stripe', loadChildren: () => import('./stripe/stripe.module').then(m => m.StripeModule), data: { preload: true} },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canLoad: [AuthGuard],  data: { preload: false } },
  { path: '**', redirectTo: '' }
];


@NgModule({
  imports: [ RouterModule.forRoot(routes, { preloadingStrategy: CustomPreload }) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
