import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { ThemeService } from '@shared/index';

import { CoreModule } from '@core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    CoreModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  bootstrap: [ AppComponent ],
  providers: [ Title ]
})
export class AppModule {

  constructor(private themeService: ThemeService,
              private titleService: Title ) {
    const theme = localStorage.getItem('theme');

    if (theme) {
      const value = JSON.parse(theme);

      this.titleService.setTitle('Bclok | ' + value.companyName);
      this.themeService.setTheme(value);
    }
  }
}
