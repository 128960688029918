import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { AuthService } from '@core/auth/auth.service';
import { ErrorModalComponent } from '@core/shared/components/error-modal/error-modal.component';

import { environment } from 'src/environments/environment';

import 'bootstrap';
import * as $ from 'jquery';
import * as firebase from 'firebase/app';
import "firebase/messaging";

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {

  modalRef: BsModalRef;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private modalService: BsModalService,
              private authService: AuthService) {}

  ngOnInit(): void {
    this.initializeErrors();

    this.initializeFirebase()
    //this function is only for make 1 time if the users hadn't hashed the passwords with Bcrypt in backend
    //this.setUserHashedPasswords();
  }

  initializeFirebase() {
    const firebaseConfig = environment.firebase;
  
    // Initialize Firebase
    if(firebase.app.length){
      firebase.initializeApp(firebaseConfig);
    }
    const messaging = firebase.messaging();
    messaging.onMessage((payload) => {
      console.log('Message received. ', payload);
      // ...
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngAfterViewChecked() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  initializeErrors(): void {
    this.authService
      .getErrors$
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(error => {
        const initialState = { error };
        const config = { class: 'modal-sm', keyboard: false, ignoreBackdropClick: true, initialState: initialState };
        this.modalRef = this.modalService.show(ErrorModalComponent, { ...config} );
      });
  }

  setUserHashedPasswords(): void {
    this.authService.passwordsHash().subscribe(res => console.log(res));
  }
}
