import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { JwtModule } from '@auth0/angular-jwt';

import { ModalModule } from 'ngx-bootstrap/modal';

import { AuthRequestInterceptor, AuthErrorInterceptor, CustomPreload, Endpoint, ErrorModalComponent } from '@core/index';


export function jwtTokenGetter() {
  return JSON.stringify(localStorage.getItem('access_token'));
}


@NgModule({
    imports: [
        HttpClientModule,
        ModalModule.forRoot(),
        JwtModule.forRoot({
            config: {
                tokenGetter: jwtTokenGetter,
                whitelistedDomains: [Endpoint.LOCAL_URL],
                blacklistedRoutes: [`${Endpoint.LOCAL_API_URL}/login`]
            }
        }),
    ],
    declarations: [ErrorModalComponent],
    providers: [
        CustomPreload,
        { provide: HTTP_INTERCEPTORS, useClass: AuthRequestInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true },
        { provide: 'SERVICE_URL', useValue: Endpoint.PRODUCTION_URL },
        { provide: 'AZURE_PORTAL_STORAGE', useValue: Endpoint.AZURE_PORTAL_STORAGE },
        { provide: 'GIPHY_API_KEY', useValue: Endpoint.GIPHY_API_KEY },
    ]
})
export class CoreModule {}