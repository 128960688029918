import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { AuthService } from '@core/auth/auth.service';
import { Error } from '@core/shared/models/error.model';


@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent {

  error: Error;

  constructor(public modalRef: BsModalRef,
              private authService: AuthService) {}

  logOut(): void {
    this.authService.logout();
    this.modalRef.hide();
  }

}
