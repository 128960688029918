export class Theme {
   backgroundPrimary: string;
   backgroundSecondary: string;
   backgroundDefault: string;
   backgroundTertiary: string;
   backgroundQuaternary: string;

   fontPrimary: string;
   fontSecondary: string;
   fontDefault: string;
   fontTertiary: string;

   buttonPrimary: string;
   buttonSecondary: string;
   buttonDefault: string;

   buttonPrimaryFont: string;
   buttonSecondaryFont: string;

   normalStateBackground: string;
   savedStateBackground: string;
   submitStateBackground: string;

   tablePrimary: string;
   tableSecondary: string;
   tableDefault: string;
   tableTertiary: string;
   tableQuaternary: string;

   badgePrimary: string;
   companyName: string;
   id: number;
   template: boolean;
   templateName: string;
   companyId: number;
   LoginLogo: string;
   DashBoardLogo: string;
   logoDashboardUrl: string;
   logoLoginUrl: string;

}
